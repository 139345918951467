.eventName {
    color: rgb(167, 19, 19);
    font-size: 20px;
}

.longEventName {
    color: rgb(167, 19, 19);
    font-size: 16px;
}

.eventDate {
    font-size: 22px;
}

#sevacardcontainers .card {
    padding: 5px;
    margin: 5px;
}